import React, { useState } from 'react'
import { Grid, Button } from "@material-ui/core"
import actions from "../actions"
import { toastError, toastSuccess, saveObject } from '../utils'
import bglogin from "../assets/images/galleryLoginBg.jpg"

function Login(props) {
    const [user, setUser] = useState({
        admin_email: "",
        password: ""
    })
    const [loginLoad, setLoginLoad] = useState(false)

    const onChange = (key, e) => {
        let newUser = { ...user }
        newUser[key] = e.target.value
        setUser(newUser)
    }

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            onSubmit();
        }
    }

    const onSubmit = () => {
        setLoginLoad(true)
        actions.login(user).then(res => {
            setLoginLoad(false)
            toastSuccess(res.message)
            saveObject('auth', res.token)
            props.history.push('/')
        }).catch(err => {
            setLoginLoad(false)
            toastError(err.message)
        })
    }

    return (
        <Grid container className='login-container'>
            <Grid item xs={12} className="d-f">
                <div className="login-div d-f">
                    <div className="m-a">
                        <h3 className="login-heading">Admin Login</h3>
                        <input
                            type="text"
                            className='input-text-field'
                            placeholder='Enter email'
                            value={user.admin_email}
                            // style={{}}
                            // onKeyPress={handleKeyPress}
                            onChange={e => onChange('admin_email', e)}
                        />

                        <input
                            type="password"
                            className='input-text-field'
                            placeholder='Enter password'
                            value={user.password}
                            style={{ marginTop: 20 }}
                            onKeyPress={handleKeyPress}
                            onChange={e => onChange('password', e)}
                        />

                        <Button style={{ marginTop: 20, width: 150 }} variant="contained" color="primary" onClick={onSubmit}>
                            {loginLoad ? 'Please Wait...' : 'Login'}
                        </Button>
                    </div>

                </div>
            </Grid>
        </Grid>
    )
}

export default Login
