import React from 'react';
import { BrowserRouter as Router, Switch, Redirect, Route } from "react-router-dom"
import { isLoggedIn } from "./utils"
import AdminHOC from './components/AdminHOC';
import Login from './components/Login';
import Banner from './components/banner/Banner';
import Categories from './components/categories/Categories';
import VideoLink from './components/videoLink/VideoLink';
import Featured from './components/featured/Featured';
import Notifications from "./components/notifications/Notifications"
import ViewNotifications from './components/notifications/ViewNotifications';

const PriavteRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn() ? (
        <AdminHOC {...props}>
          <Component {...props} />
        </AdminHOC>
      )
        : (
          <Redirect to='/login' />
        )
    }
  />
);

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => isLoggedIn() ? <Redirect to="/banner" /> : <Component {...props} />
    }
  />
);

function App() {
  return (
    <Router>
      <Switch>
        <PublicRoute exact path='/login' component={Login} />
        <PriavteRoute exact path='/' component={Banner} />
        <PriavteRoute exact path='/categories' component={Categories} />
        <PriavteRoute exact path="/linkvideo/:name/:id" component={VideoLink} />
        <PriavteRoute exact path="/featured" component={Featured} />
        <PriavteRoute exact path="/notifications" component={Notifications} />
        <PriavteRoute exact path="/viewnotifications" component={ViewNotifications} />
      </Switch>
    </Router>
  );
}

export default App;
