import React, { useEffect, useState } from 'react'
import actions from '../../actions'
import { Grid, Paper, Button } from "@material-ui/core"
import { toastError, toastSuccess } from '../../utils'

function Notifications(props) {

    const [notification, setNotifications] = useState({
        text: '',
    })
    const [userCount, setUserCount] = useState(0)

    useEffect(() => {
        props.setLoader(true)
        actions.getUserCount().then(res => {
            props.setLoader(false)
            setUserCount(res.userCount)
        }).catch(err => {
            props.setLoader(false)
        })
    }, [])

    const onChange = key => e => {
        const { value = "" } = e.target;
        let newNotification = { ...notification };
        newNotification[key] = value
        setNotifications(newNotification);
    };

    const sendNotification = (offset) => {
        if (!notification.text) {
            return toastError('Please fill details first')
        }
        const sendObj = {
            text: notification.text,
            offset: offset,
        }
        props.setLoader(true)
        actions.sendNotifications(sendObj).then(res => {
            props.setLoader(false)
            toastSuccess(res.message)
        }).catch(err => {
            props.setLoader(false)
            toastError(err.message || 'Something went wrong')
        })
    }

    let totalPages = Math.ceil(Number(userCount) / 1000);
    let buttonArray = []
    let count = 1000;
    for (let i = 0; i < totalPages; i++) {
        if (totalPages === 1) {
            buttonArray.push(userCount)
        }
        else {
            buttonArray.push(count)
        }
        count = count + 1000;
    }

    return (
        <Grid container className='notification-container'>
            <Grid item xs={12} className='user-count'>
                <p>Total User(s): <span>{userCount}</span></p>
            </Grid>
            <Grid item xs={12}>
                <Grid container className='notification-details'>
                    <Grid item xs={12} sm={10} md={6} xl={5}>
                        <Paper style={{ width: '100%' }}>

                            <Grid container className='container'>
                                <Grid item xs={3} className='label'>
                                    <label>Text:</label>
                                </Grid>
                                <Grid item xs={9} className='data'>
                                    <input
                                        type='text'
                                        className='input-text-field'
                                        value={notification.text}
                                        onChange={onChange('text')}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container className='button-array'>
                    <Grid item xs={12} sm={12} md={10} xl={8}>
                        <div>
                            {buttonArray.length ? <p>Send to:</p> : null}
                            {buttonArray.map((item, key) => {
                                if (key === 0) {
                                    return <Button key={key} className='main-button' onClick={() => sendNotification(0)}>{item} Users</Button>
                                } else if (key === totalPages - 1) {
                                    return <Button key={key} className='main-button' onClick={() => sendNotification(buttonArray[key - 1])}>{buttonArray[key - 1] + 1} - {(1000 - (count - userCount)) + (count - 1000)} Users</Button>
                                } else {
                                    return <Button key={key} className='main-button' onClick={() => sendNotification(buttonArray[key - 1])}>{buttonArray[key - 1] + 1} - {item} Users</Button>
                                }
                            })}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Notifications
