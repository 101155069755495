import React, { useEffect, useState } from 'react'
import actions from "../../actions"
import VideoLinkCard from './VideoLinkCard'
import Grid from "@material-ui/core/Grid"
import { toastError, toastSuccess } from '../../utils'
import AddModal from "../common/AddModal"
import { bannerTypes, featuredType } from '../../constants'
import SelectCmp from "react-select"

function VideoLink(props) {

    const intitalVideoLink = {
        category_id: Number(props.match.params.id),
        link: "",
        featured: 1,
        type: "video"
    }

    const [allVideoLink, setAllVideoLink] = useState([])
    const [open, setOpen] = useState(false)
    const [videoLink, setVideoLink] = useState({ ...intitalVideoLink })

    useEffect(() => {
        getCategoriesVideo()
    }, [])

    const getCategoriesVideo = () => {
        props.setLoader(true)
        actions.getCategorieById(props.match.params.id).then(res => {
            props.setLoader(false)
            setAllVideoLink(res.body)
        }).catch(err => props.setLoader(false))
    }

    const deleteVideoLink = (video_linkID) => {
        props.setLoader(true)
        actions.deleteVideoLink({ video_linkID }).then(res => {
            props.setLoader(false)
            toastSuccess(res.message)
            getCategoriesVideo()
        }).catch(err => {
            props.setLoader(false)
        })
    }


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        clearFiles()
    };

    const clearFiles = () => {
        setVideoLink({ ...intitalVideoLink })
    }

    const onChangeHandler = (key, e, isSelect) => {
        const newVideoLink = { ...videoLink }
        if (isSelect) {
            newVideoLink[key] = e.value
        } else {
            newVideoLink[key] = e.target.value
        }
        setVideoLink(newVideoLink)
    }

    const onFileHandler = (e) => {
        let newVideoLink = { ...videoLink }
        const file = e.target.files[0];
        props.setLoader(true)
        actions.upload(file).then((res) => {
            props.setLoader(false)
            newVideoLink['link'] = res.body.filename
            setVideoLink(newVideoLink)
            toastSuccess(res.message)
        }).catch((err) => {
            props.setLoader(false)
            toastError(err.message)
        })
    }

    const makeThisFeatured = (video_linkID) => {
        props.setLoader(true)
        actions.makeFeaturedContent({ video_linkID }).then(res => {
            props.setLoader(false)
            toastSuccess(res.message)
            getCategoriesVideo()
        }).catch(err => {
            toastError(err.message)
            props.setLoader(false)
        })
    }

    const onSubmit = () => {
        props.setLoader(true)
        actions.createVideoLink(videoLink).then(res => {
            props.setLoader(false)
            toastSuccess(res.message)
            setOpen(false)
            getCategoriesVideo()
        }).catch(err => {
            props.setLoader(false)
            toastError(err.message)
        })
    }

    const selectedType = bannerTypes.find(item => item.value === videoLink.type)
    const selectedFeature = featuredType.find(item => item.value === videoLink.featured)

    return (
        <Grid container className="video-link-container">
            <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between", margin: "10px 0px" }}>
                <p className="heading">Category: <span>{props.match.params.name}</span></p>
                <AddModal
                    buttonName='+ Add Video Link'
                    heading="Add Video Link"
                    handleClickOpen={handleClickOpen}
                    handleClose={handleClose}
                    open={open}
                    onSubmit={onSubmit}
                    clearFiles={clearFiles}
                    fullWidth
                    maxWidth='xs'
                >
                    <Grid container>
                        <Grid item xs={4} className="d-f">
                            <p className="m-a">Select Type</p>
                        </Grid>
                        <Grid item xs={8}>
                            <SelectCmp
                                options={bannerTypes}
                                value={selectedType}
                                onChange={(e) => onChangeHandler('type', e, true)}
                            />

                        </Grid>
                    </Grid>

                    <Grid container className="m-t-20">
                        <Grid item xs={4} className="d-f">
                            <p className="m-a">Featured</p>
                        </Grid>
                        <Grid item xs={8}>
                            <SelectCmp
                                options={featuredType}
                                value={selectedFeature}
                                onChange={(e) => onChangeHandler('featured', e, true)}
                            />
                        </Grid>
                    </Grid>


                    {
                        videoLink.type === "video" ?
                            <Grid container className="m-t-20">
                                <Grid item xs={4} className="d-f">
                                    <p className="m-a">Banner Link</p>
                                </Grid>
                                <Grid item xs={8}>
                                    <input
                                        type="text"
                                        className="input-text-field"
                                        placeholder="Enter Banner Link"
                                        value={videoLink.banner_link}
                                        onChange={(e) => onChangeHandler('link', e, false)}
                                    />
                                </Grid>
                            </Grid>
                            :
                            <Grid container style={{ margin: "20px 0px 25px 0px" }}>
                                <Grid item xs={4} className="d-f">
                                    <p className="m-a">Upload Image</p>
                                </Grid>
                                <Grid item xs={8}>
                                    <input
                                        type='file'
                                        accept="image/x-png,image/gif,image/jpeg"
                                        onChange={onFileHandler}
                                    />
                                </Grid>
                            </Grid>
                    }

                </AddModal>
            </Grid>
            {
                allVideoLink.map((link, k) => {
                    return <VideoLinkCard
                        key={k}
                        videoLink={link}
                        deleteVideoLink={deleteVideoLink}
                        makeThisFeatured={makeThisFeatured}
                    />
                })
            }
        </Grid>
    )
}

export default VideoLink
