import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        overflow: 'visible'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function AddModal(props) {

    useEffect(() => {
        return () => {
            props.clearFiles()
        }
    }, [])

    const { buttonName, heading, fullWidth, maxWidth, open, handleClickOpen, handleClose, onSubmit, submitLabel = 'Create', visible } = props
    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                {buttonName}
            </Button>
            <Dialog scroll="body" className={`${visible ? 'modal-overflow-visible' : ''}`} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={fullWidth} maxWidth={maxWidth}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {heading}
                </DialogTitle>
                <DialogContent dividers style={{ overflow: 'visible' }}>
                    {props.children}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className='modal-create-btn' onClick={onSubmit}>
                        {submitLabel}
                    </Button>
                    <Button onClick={handleClose} className='modal-cancel-btn'>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}