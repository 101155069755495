import React, { useEffect, useState } from 'react'
import { Grid, Button, Table, TableBody, TableCell, TableRow, TableHead } from "@material-ui/core"
import actions from "../../actions"
import { toastSuccess } from '../../utils'

function ViewNotifications(props) {

    const [allNotifications, setAllNotifications] = useState([])

    useEffect(() => {
        onGetAllNotifications()
    }, [])

    const onGetAllNotifications = () => {
        props.setLoader(true)
        actions.getNotifications().then(res => {
            props.setLoader(false)
            setAllNotifications(res.body)
        }).catch(err => {
            props.setLoader(false)
        })
    }

    const onDeleteNotifications = (noti_id) => {
        props.setLoader(true)
        actions.deleteNotification({ noti_id }).then(res => {
            props.setLoader(false)
            toastSuccess(res.message)
            onGetAllNotifications()
        }).catch(() => props.setLoader(false))
    }

    return (
        <Grid container className="d-f">
            <Grid item xs={12} lg={10} xl={8} className="m-a">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ maxWidth: 100 }}>S. no</TableCell>
                            <TableCell>Text</TableCell>
                            <TableCell style={{ maxWidth: 100 }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            allNotifications && allNotifications.length ? allNotifications.map((item, key) => {
                                return <TableRow>
                                    <TableCell style={{ maxWidth: 100 }}>{key + 1}</TableCell>
                                    <TableCell>{item.text}</TableCell>
                                    <TableCell style={{ maxWidth: 100 }}><Button variant="contained" color="secondary" onClick={() => onDeleteNotifications(item.noti_id)} >DELETE</Button></TableCell>
                                </TableRow>
                            }) : <TableRow>
                                    <TableCell colSpan={3} style={{ fontWeight: 600, textAlign: "center" }}>No Data Found.</TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    )
}

export default ViewNotifications
