import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { CircularProgress, Hidden } from '@material-ui/core';
import Loader from 'react-loader-advanced';
import Clock from "./common/Clock"
import ConfirmModal from './common/ConfirmModal';
import { removeAuthObjects } from '../utils';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import CategoryIcon from '@material-ui/icons/Category';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: '300ms',
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: '300ms',
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: '300ms',
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: '300ms',
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    imageDiv: {
        width: '100%',
        height: '40px',
        display: 'flex'
    },
    imglogo: {
        maxWidth: '100%',
        maxHeight: '100%'
    }
}));

const listItems = [
    {
        label: 'Banner',
        // icon: <img style={{ height: '30px' }} src={ViewCarouselIcon} alt='' />,
        icon: <ViewCarouselIcon style={{ textAlign: "center" }} />,
        path: '/',
    },
    {
        label: 'Categories',
        icon: <CategoryIcon style={{ textAlign: "center" }} />,
        // icon: <img style={{ height: '30px' }} src={} alt='' />,
        path: '/categories'
    },
    {
        label: 'Featured',
        icon: <FeaturedPlayListIcon style={{ textAlign: "center" }} />,
        // icon: <img style={{ height: '30px' }} src={} alt='' />,
        path: '/featured'
    },
    {
        label: 'Notifications',
        icon: <NotificationsActiveIcon style={{ textAlign: "center" }} />,
        // icon: <img style={{ height: '30px' }} src={} alt='' />,
        path: '/notifications'
    },
    {
        label: 'View Notifications',
        icon: <ViewHeadlineIcon style={{ textAlign: "center" }} />,
        // icon: <img style={{ height: '30px' }} src={} alt='' />,
        path: '/viewnotifications'
    },
    {
        label: 'Logout',
        icon: <ExitToAppIcon style={{ textAlign: "center" }} />,
        path: '/login'
    },
]

export default function AdminHOC(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const [loader, setLoader] = React.useState(false);
    const [confirmOpen, setConfimOpen] = React.useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const header = () => {
        const { path } = props.match
        switch (path) {
            case '/':
                return 'Banner'
            case '/categories':
                return 'Categories'
            case '/featured':
                return 'Featured'
            case '/notifications':
                return 'Notifications'
            case '/viewnotifications':
                return 'View Notifications'
            default: {
                return ''
            }
        }
    }

    const handleConfirmClickOpen = () => {
        setConfimOpen(true);
    };

    const handleConfirmClose = () => {
        setConfimOpen(false);
    };

    const onConfirmLogout = () => {
        onSetLoader(true)
        removeAuthObjects()
        setTimeout(() => {
            onSetLoader(false)
            return props.history.push('/login')
        }, 1000)
    }

    const onSetLoader = (action) => {
        setLoader(action)
    }

    const LoaderStyle = {
        height: "100%",
        overflow: "hidden"
    }
    return (
        <Loader className="main-loader" style={loader ? LoaderStyle : null} message={<CircularProgress size={50} style={{ color: "white" }} />} show={loader}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, {
                                [classes.hide]: open,
                            })}
                        >
                            <MenuIcon />
                        </IconButton>
                        <div style={{ width: '100%', justifyContent: "space-between", display: "flex" }}>
                            <Typography variant="h6" noWrap style={{ alignSelf: "center" }}>
                                {header()}
                            </Typography>
                            <Hidden only={['xs']}>
                                <div style={{ alignSelf: 'center', display: "flex" }}>
                                    <p style={{ paddingRight: '23px', marginRight: '23px', fontSize: '18px', borderRight: '1px solid #d8d8d8' }}>Welcome, Harjaspreet</p>
                                    <Clock />
                                </div>
                            </Hidden>
                        </div>
                    </Toolbar>

                </AppBar>
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                >
                    <div className={classes.toolbar}>
                        <div className={classes.imageDiv}>
                            {/* <img className={classes.imglogo} src={logo} alt="" onClick={() => props.history.push('/newOrders')} /> */}
                            <h2 style={{ fontWeight: '400', textAlign: 'center', fontSize: '23px', margin: 'auto' }}>Dashboard</h2>
                        </div>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        {listItems.map((text, index) => {
                            if (text.label === 'Logout') {
                                return <ListItem style={{ backgroundColor: props.location.pathname === text.path ? '#dedede' : '' }} button key={text.label} onClick={handleConfirmClickOpen}>
                                    <ListItemIcon>{text.icon}</ListItemIcon>
                                    <ListItemText primary={text.label} />
                                </ListItem>
                            } else if (text.label === 'Categories') {
                                return <ListItem style={{ backgroundColor: props.location.pathname === '/categories' ? '#dedede' : '' }} button key={text.label} onClick={() => props.history.push(text.path)}>
                                    <ListItemIcon>{text.icon}</ListItemIcon>
                                    <ListItemText primary={text.label} />
                                </ListItem>
                            } else {
                                return <ListItem style={{ backgroundColor: props.location.pathname === text.path ? '#dedede' : '' }} button key={text.label} onClick={() => props.history.push(text.path)}>
                                    <ListItemIcon>{text.icon}</ListItemIcon>
                                    <ListItemText primary={text.label} />
                                </ListItem>
                            }
                        })}
                    </List>

                </Drawer>
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    {/* {props.children} */}
                    {React.cloneElement(props.children, { setLoader: onSetLoader })}
                </main>
                <ConfirmModal
                    message={`Are you sure you want to Logout?`}
                    open={confirmOpen}
                    title={`Confirm Logout`}
                    onSubmitConfirm={onConfirmLogout}
                    handleClose={handleConfirmClose}
                >

                </ConfirmModal>
            </div>
        </Loader>
    );
}
