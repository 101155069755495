import React from 'react'
import { Grid, Button } from "@material-ui/core"
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import ImageIcon from '@material-ui/icons/Image';
import { IMAGE_URL, VIDEO_URL } from '../../constants';

function BannerCard(props) {
    const { banner, deleteBanner } = props
    const { banner_id, banner_link = "", type = "" } = banner;
    return (
        <Grid item xs={12} sm={6} md={3} style={{ padding: 10 }}>
            <div className="banner-card">
                <h4>{type === 'video' ? <PlayCircleFilledIcon /> : <ImageIcon />}<span>{type.toUpperCase()}</span></h4>
                <div className="content">
                    {
                        type === 'video' ? <div className="video-link">
                            <a href={VIDEO_URL.concat(banner_link)} target="_blank">
                                {banner_link}
                            </a>
                        </div>
                            : <div className="image-div">
                                <img src={IMAGE_URL.concat(banner_link)} alt="" />
                            </div>
                    }
                </div>
                <div style={{ textAlign: "center", margin: 10 }}><Button variant="contained" color="secondary" onClick={() => deleteBanner(banner_id)}>Delete</Button></div>
            </div>
        </Grid>
    )
}

export default BannerCard
