import {
    loginAPI,
    getBannersAPI,
    createBannerAPI,
    deleteBannerAPI,
    uploadAPI,
    createCategoriesAPI,
    getCategoriesNamesAPI,
    deletecategoryAPI,
    createVideoLinkAPI,
    deleteVideoLinkAPI,
    getCategorieByIdAPI,
    getFeaturedContentAPI,
    removeFeaturedContentAPI,
    getUserCountAPI,
    sendNotificationsAPI,
    deleteNotificationAPI,
    getNotificationsAPI,
    makeFeaturedContentAPI
} from "../apis/allApis"
import { toastError } from "../utils"

export function deleteNotification(data) {
    return new Promise((resolve, reject) => {
        deleteNotificationAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err);
        })
    })
}

export function getNotifications(data) {
    return new Promise((resolve, reject) => {
        getNotificationsAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err);
        })
    })
}

export function makeFeaturedContent(data) {
    return new Promise((resolve, reject) => {
        makeFeaturedContentAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err);
        })
    })
}

export function sendNotifications(data) {
    return new Promise((resolve, reject) => {
        sendNotificationsAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err);
        })
    })
}

export function getUserCount(data) {
    return new Promise((resolve, reject) => {
        getUserCountAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err);
        })
    })
}

export function getFeaturedContent(data) {
    return new Promise((resolve, reject) => {
        getFeaturedContentAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err);
        })
    })
}

export function removeFeaturedContent(data) {
    return new Promise((resolve, reject) => {
        removeFeaturedContentAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err);
        })
    })
}

export function getCategorieById(data) {
    return new Promise((resolve, reject) => {
        getCategorieByIdAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err);
        })
    })
}

export function createVideoLink(data) {
    return new Promise((resolve, reject) => {
        createVideoLinkAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err);
        })
    })
}

export function deleteVideoLink(data) {
    return new Promise((resolve, reject) => {
        deleteVideoLinkAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err);
        })
    })
}

export function createCategories(data) {
    return new Promise((resolve, reject) => {
        createCategoriesAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err);
        })
    })
}

export function getCategoriesNames(data) {
    return new Promise((resolve, reject) => {
        getCategoriesNamesAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err);
        })
    })
}

export function deletecategory(data) {
    return new Promise((resolve, reject) => {
        deletecategoryAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err);
        })
    })
}

export function upload(data) {
    return new Promise((resolve, reject) => {
        uploadAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err);
        })
    })
}

export function login(data) {
    return new Promise((resolve, reject) => {
        loginAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err);
        })
    })
}

export function getBanners(data) {
    return new Promise((resolve, reject) => {
        getBannersAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err);
        })
    })
}

export function createBanner(data) {
    return new Promise((resolve, reject) => {
        createBannerAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err);
        })
    })
}

export function deleteBanner(data) {
    return new Promise((resolve, reject) => {
        deleteBannerAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err);
        })
    })
}