import React from 'react'
import { Grid, Button } from "@material-ui/core"
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import ImageIcon from '@material-ui/icons/Image';
import { IMAGE_URL, VIDEO_URL } from '../../constants';

function VideoLinkCard(props) {
    const { videoLink, deleteVideoLink, makeThisFeatured } = props
    const { video_linkID, link, featured, type } = videoLink
    return (
        <Grid item xs={12} sm={6} md={4} lg={3} className="video-link-card">
            <div className="video-link-div">
                <div className="heading-div">
                    <h4>{type === 'video' ? <PlayCircleFilledIcon /> : <ImageIcon />}<span>{type.toUpperCase()}</span></h4>
                    {featured === 1 ?
                        <p className="featured-p">Featured</p> : <div className="featured-div">
                            <p onClick={() => makeThisFeatured(video_linkID)}>Make Featured</p>
                        </div>
                    }
                </div>
                <div className="content">
                    {
                        type === 'video' ? <div className="video-link">
                            <a href={VIDEO_URL.concat(link)} target="_blank">
                                {link}
                            </a>
                        </div>
                            : <div className="image-div">
                                <img src={IMAGE_URL.concat(link)} alt="" />
                            </div>
                    }
                </div>
                <div style={{ textAlign: "center", margin: 10 }}><Button variant="contained" color="secondary" onClick={() => deleteVideoLink(video_linkID)}>Delete</Button></div>
            </div>
        </Grid>
    )
}

export default VideoLinkCard
