import React, { useEffect, useState } from 'react'
import { Grid } from "@material-ui/core"
import actions from "../../actions"
import BannerCard from './BannerCard'
import { toastSuccess, toastError } from '../../utils'
import AddModal from '../common/AddModal'
import { bannerTypes } from '../../constants'
import SelectCmp from "react-select"

function Banner(props) {
    const [allBanners, setAllBanners] = useState([])
    const [open, setOpen] = useState(false)
    const [banner, setBanner] = useState({
        banner_link: "",
        type: "video"
    })

    useEffect(() => {
        getAllBanners()
    }, [])

    const getAllBanners = () => {
        props.setLoader(true)
        actions.getBanners().then(res => {
            props.setLoader(false)
            setAllBanners(res.body)
        }).catch(() => props.setLoader(false))
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        clearFiles()
    };

    const clearFiles = () => {
        setBanner({
            banner_link: "",
            type: "video"
        })
    }

    const onDeleteBanner = (banner_id) => {
        props.setLoader(true)
        actions.deleteBanner({ banner_id }).then(res => {
            props.setLoader(false)
            toastSuccess(res.message)
            getAllBanners()
        }).catch(() => props.setLoader(false))
    }

    const onChangeType = (e) => {
        setBanner({ ...banner, type: e.value })
    }

    const onChangeVideo = (e) => {
        setBanner({ ...banner, banner_link: e.target.value })
    }

    const onFileHandler = (e) => {
        let newbanner = { ...banner }
        const file = e.target.files[0];
        props.setLoader(true)
        actions.upload(file).then((res) => {
            props.setLoader(false)
            newbanner['banner_link'] = res.body.filename
            // previewsFile(file)
            setBanner(newbanner)
            toastSuccess(res.message)
        }).catch((err) => {
            props.setLoader(false)
            toastError(err.message)
        })
    }

    const onSubmit = () => {
        props.setLoader(true)
        actions.createBanner(banner).then(res => {
            props.setLoader(false)
            getAllBanners()
            setOpen(false)
            setBanner({
                banner_link: "",
                type: "video"
            })
            toastSuccess(res.message)
        }).catch(err => {
            props.setLoader(false)
            toastError(err.message)
        })
    }

    const selectedType = bannerTypes.find(item => item.value === banner.type)
    return (
        <Grid container className="banner-container">
            <Grid item xs={12} className='move-add-button-right'>
                <AddModal
                    buttonName='+ Add Banner'
                    heading="Add Banner"
                    handleClickOpen={handleClickOpen}
                    handleClose={handleClose}
                    open={open}
                    onSubmit={onSubmit}
                    clearFiles={clearFiles}
                    fullWidth
                    maxWidth='xs'
                >
                    <Grid container>
                        <Grid item xs={4} className="d-f">
                            <p className="m-a">Banner Type</p>
                        </Grid>
                        <Grid item xs={8}>
                            <SelectCmp
                                options={bannerTypes}
                                value={selectedType}
                                onChange={onChangeType}
                            />

                        </Grid>
                    </Grid>
                    {
                        banner.type === "video" ?
                            <Grid container className="m-t-20">
                                <Grid item xs={4} className="d-f">
                                    <p className="m-a">Banner Link</p>
                                </Grid>
                                <Grid item xs={8}>
                                    <input
                                        type="text"
                                        className="input-text-field"
                                        placeholder="Enter Banner Link"
                                        value={banner.banner_link}
                                        onChange={onChangeVideo}
                                    />
                                </Grid>
                            </Grid>
                            :
                            <Grid container style={{ margin: "20px 0px 25px 0px" }}>
                                <Grid item xs={4} className="d-f">
                                    <p className="m-a">Upload Image</p>
                                </Grid>
                                <Grid item xs={8}>
                                    <input
                                        type='file'
                                        accept="image/x-png,image/gif,image/jpeg"
                                        onChange={onFileHandler}
                                    />
                                </Grid>
                            </Grid>
                    }
                </AddModal>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    {
                        allBanners.map((banner, key) => <BannerCard key={key} banner={banner} deleteBanner={onDeleteBanner} />)
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Banner
