import React from 'react'
import { Grid, Button } from "@material-ui/core"
import { IMAGE_URL } from '../../constants'

function CategoryCard(props) {
    const { category, deleteCategory, goToVideoLink } = props
    return (
        <Grid item xs={12} sm={6} md={4} lg={3} className="category-card">
            <div className="category-card-div" onClick={() => goToVideoLink(category.category_name, category.category_id)}>
                <h4>{category.category_name}</h4>
                <div className="image-div">
                    <img src={IMAGE_URL.concat(category.category_image)} alt="" />
                </div>
                <div style={{ textAlign: "center", margin: 10 }}><Button variant="contained" color="secondary" onClick={(e) => { e.stopPropagation(); deleteCategory(category.category_id) }}>Delete</Button></div>
            </div>
        </Grid >
    )
}

export default CategoryCard
