import React, { useEffect, useState } from 'react'
import { Grid } from "@material-ui/core"
import actions from "../../actions"
import { toastError, toastSuccess } from '../../utils'
import CategoryCard from './CategoryCard'
import AddModal from "../common/AddModal"

function Categories(props) {

    const [allCategories, setAllCategories] = useState([])
    const [open, setOpen] = useState(false)
    const [category, setCategory] = useState({
        category_name: "",
        category_image: ""
    })

    useEffect(() => {
        getAllCategories();
    }, [])

    const onChange = (key, e) => {
        const newCategory = { ...category }
        newCategory[key] = e.target.value
        setCategory(newCategory)
    }


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        clearFiles()
    };

    const clearFiles = () => {
        setCategory({
            category_name: "",
            category_image: ""
        })
    }

    const getAllCategories = () => {
        props.setLoader(true)
        actions.getCategoriesNames().then(res => {
            props.setLoader(false)
            setAllCategories(res.body)
        }).catch(err => {
            props.setLoader(false)
            toastError(err.message)
        })
    }

    const onDeleteCategories = (category_id) => {
        props.setLoader(true)
        actions.deletecategory({ category_id }).then(res => {
            props.setLoader(false)
            toastSuccess(res.message)
            getAllCategories()
        }).catch((err) => {
            toastError(err.message)
            props.setLoader(false)
        })
    }

    const onFileHandler = (e) => {
        let newCategory = { ...category }
        const file = e.target.files[0];
        props.setLoader(true)
        actions.upload(file).then((res) => {
            props.setLoader(false)
            newCategory['category_image'] = res.body.filename
            setCategory(newCategory)
            toastSuccess(res.message)
        }).catch((err) => {
            props.setLoader(false)
            toastError(err.message)
        })
    }

    const goToVideoLink = (name, id) => {
        props.setLoader(true)
        setTimeout(() => {
            props.setLoader(false)
            props.history.push(`/linkvideo/${name}/${id}`)
        }, 500)
    }


    const onSubmit = () => {
        if (!category.category_name) {
            return toastError('Name is Required')
        }
        props.setLoader(true)
        actions.createCategories(category).then(res => {
            props.setLoader(false)
            toastSuccess(res.message)
            setOpen(false)
            getAllCategories()
        }).catch(err => {
            toastError(err.message)
            props.setLoader(false)
        })

    }

    return (
        <Grid container className="banner-container">
            <Grid item xs={12} className='move-add-button-right'>
                <AddModal
                    buttonName='+ Add Category'
                    heading="Add Category"
                    handleClickOpen={handleClickOpen}
                    handleClose={handleClose}
                    open={open}
                    onSubmit={onSubmit}
                    clearFiles={clearFiles}
                    fullWidth
                    maxWidth='xs'
                >
                    <Grid container>
                        <Grid item xs={4} className="d-f">
                            <p className="m-a">Category Name</p>
                        </Grid>
                        <Grid item xs={8}>
                            <input
                                type="text"
                                className="input-text-field"
                                placeholder="Enter Category Name"
                                value={category.category_name}
                                onChange={e => onChange('category_name', e)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ margin: "20px 0px 25px 0px" }}>
                        <Grid item xs={4} className="d-f">
                            <p className="m-a">Upload Image</p>
                        </Grid>
                        <Grid item xs={8}>
                            <input
                                type='file'
                                accept="image/x-png,image/gif,image/jpeg"
                                onChange={onFileHandler}
                                placeholder="Enter Category Image"
                            />
                        </Grid>
                    </Grid>
                </AddModal>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    {
                        allCategories.map((category, key) => <CategoryCard
                            key={key}
                            category={category}
                            deleteCategory={onDeleteCategories}
                            goToVideoLink={goToVideoLink}
                        />)
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Categories
