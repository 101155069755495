import axios from "axios"
import { API_URL } from "./constants"
import { toast } from "react-toastify"

export function apiGet(endpoint) {
    const finalEndpoint = finalUrl(endpoint);
    return apiRequest(finalEndpoint, {}, "get", {});
}

export function apiPost(endpoint, data) {
    const finalEndpoint = finalUrl(endpoint);
    return apiRequest(finalEndpoint, data, "post", {});
}

export function apiPut(endpoint, data) {
    const finalEndpoint = finalUrl(endpoint);
    return apiRequest(finalEndpoint, data, "put", {});
}

export function apiDelete(endpoint /* , data, header */) {
    /*   const user = getObject("user");
      console.log(data, "wokring")
      const headers = { ...header, Authorization: user.token }; */
    const finalEndpoint = finalUrl(endpoint);
    return apiRequest(finalEndpoint, {}, "delete", {});
}

export function apiPatch(endpoint, data) {
    const finalEndpoint = finalUrl(endpoint);
    return apiRequest(finalEndpoint, data, "patch", {});
}

export function getHeaders() {
    const auth = getObject("auth") || {};
    if (auth) {
        return {
            Authorization: auth
        };
    }
    return {};
}

export function apiRequest(endPoint, data, method, headers) {
    return new Promise((resolve, reject) => {
        headers = {
            ...getHeaders(),
            ...headers
        };

        if (method === "get" || method === "delete") {
            data = {
                params: data,
                // paramsSerializer: function (params) {
                //   return queryString.stringify(params, { arrayFormat: "repeat" });
                // },
                headers
            };
        }
        axios[method](endPoint, data, { headers })
            .then(response => {
                const { data } = response;
                if (data.status === 200) {
                    return resolve(data);
                }
                return reject(data);
            })
            .catch(error => {
                return reject(error);
            });
    });
}

const finalUrl = slug => {
    return API_URL + slug;
};

export const saveObject = (key, data) => {
    let obj = JSON.stringify(data);
    localStorage.setItem(key, obj);
};

export const removeObject = key => {
    localStorage.removeItem(key);
};

export const getObject = key => {
    return JSON.parse(localStorage.getItem(key));
};

export const isLoggedIn = () => {
    let auth = getObject("auth");
    if (auth) {
        return true;
    }
    return false;
};

export function toastSuccess(message, time = 3000) {
    toast.success(message, {
        autoClose: time,
        hideProgressBar: true
    })
}

export function toastError(message, time = 3000) {
    toast.error(message || "Something went wrong", {
        autoClose: time,
        hideProgressBar: true
    })
}

export function removeAuthObjects() {
    removeObject('auth')
}