import React, { useEffect, useState } from 'react'
import { Grid } from "@material-ui/core"
import actions from "../../actions"
import FeaturedCard from './FeaturedCard'
import { toastError, toastSuccess } from '../../utils'

function Featured(props) {

    const [allFeatured, setAllFeatured] = useState([])

    useEffect(() => {
        getAllFeatured()
    }, [])

    const getAllFeatured = () => {
        props.setLoader(true)
        actions.getFeaturedContent().then(res => {
            props.setLoader(false)
            setAllFeatured(res.body)
        }).catch(err => props.setLoader(false))
    }

    const deleteFeatured = (video_linkID) => {
        props.setLoader(true)
        actions.removeFeaturedContent({ video_linkID }).then(res => {
            props.setLoader(false)
            toastSuccess(res.message)
            getAllFeatured()
        }).catch(err => {
            props.setLoader(false)
            toastError(err.message)
        })
    }

    return (
        <Grid container>
            {
                allFeatured.map((item, k) => {
                    return <FeaturedCard
                        key={k}
                        item={item}
                        deleteFeatured={deleteFeatured}
                    />
                })
            }
        </Grid>
    )
}

export default Featured
