export const API_URL = "https://backend.ambalaproductions.in"
export const IMAGE_URL = "https://backend.ambalaproductions.in/"
export const VIDEO_URL = "https://www.youtube.com/watch?v="

export const bannerTypes = [
    {
        label: 'Video',
        value: 'video'
    },
    {
        label: 'Image',
        value: 'image'
    }
]

export const featuredType = [
    {
        label: 'Yes',
        value: 1
    },
    {
        label: 'No',
        value: 0
    },
]