import { apiGet, apiPost } from "../utils"

export function loginAPI(data) {
    return apiPost('/api/login', data);
}

export function getBannersAPI(data) {
    return apiGet('/api/getBanners', data);
}

export function createBannerAPI(data) {
    return apiPost('/api/createBanner', data);
}

export function deleteBannerAPI(data) {
    return apiPost('/api/deleteBanner', data);
}

export function uploadAPI(file) {
    const data1 = new FormData;
    data1.append("image", file)
    return apiPost(`/api/upload`, data1)
}

export function getCategoriesNamesAPI(data) {
    return apiGet('/api/getCategoriesNames', data);
}

export function getCategorieByIdAPI(data) {
    return apiGet(`/api/getCategorieById?category_id=${data}`);
}

export function createCategoriesAPI(data) {
    return apiPost('/api/createCategories', data);
}

export function deletecategoryAPI(data) {
    return apiPost('/api/deletecategory', data);
}

export function createVideoLinkAPI(data) {
    return apiPost('/api/createVideoLink', data);
}

export function deleteVideoLinkAPI(data) {
    return apiPost('/api/deleteVideoLink', data);
}

export function getFeaturedContentAPI(data) {
    return apiGet('/api/getFeaturedContent', data);
}

export function removeFeaturedContentAPI(data) {
    return apiPost('/api/removeFeaturedContent', data);
}

export function getUserCountAPI(data) {
    return apiGet('/api/getUserCount', data);
}

export function sendNotificationsAPI(data) {
    return apiPost('/api/sendNotifications', data);
}

export function deleteNotificationAPI(data) {
    return apiPost('/api/deleteNotification', data);
}

export function getNotificationsAPI(data) {
    return apiGet('/api/getNotifications', data);
}

export function makeFeaturedContentAPI(data) {
    return apiPost('/api/makeFeaturedContent', data);
}